import React, { Fragment, useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import API from "../constants/API";
import Swal from "sweetalert2";
import { Document, Page, pdfjs } from "react-pdf";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ACCESS_S3_FILE } from "../comman/url";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";

const useStyles = makeStyles((theme) => ({
  tools: {
    zIndex: 9,
    top: 0,
    left: 0,
  },
  previewbtn: {
    color: "#14142A !important",
  },
  popupclose: {
    position: "absolute",
    right: "13px",
    top: "0px",
    cursor:"pointer"
  },
  popupcloseicon: {
    fontSize: "30px",
    color: "gray",
  },
}));

// import { NavLink } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js`;

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function Preview(props) {
  const {
    filePath,
    Toggle,
    DetailsToggle,
    emailContentFilePath = "Mail body not available!",
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [flag, setFlag] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeTab, setActiveTab] = useState("1");
  const styleclasses = useStyles();

  const tabToggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  setFlag("true");
    //setPageNumber(1);
  }
const onImageSuccess =async ()=>{
 setFlag("true");
}
  const onDocumentError = async (e) => {
    console.log("error", e);
  };
  const changePage = useCallback(
    (offset) =>
      setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    []
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [extensionOfUrl,setExtensionOfUrl]=useState("");
  const [documentIndex, setIndex] = useState(1);
  const [pdfDimension, setPdfDimension] = useState({
    width: 900,
    height: 900,
  });
  const [maxWidth, setMaxWidth] = React.useState('lg');

  let GetFileAccess = async () => {

    if (filePath) {
      let extension=filePath.split('.').pop();
      setExtensionOfUrl(extension)
      setLoading(true);
      const config = {
        method: "POST",
        url: ACCESS_S3_FILE,
        data: {
          filePath,
        },
        headers: authHeader(),
      };
      try {
        const response = await API(config);

        const { status, url } = response.data;
        if (status === "Success") {
          setUrl(url);
        }
      } catch (error) {
        let errorObj = Object.assign({}, error);
        let { data } = errorObj.response;
        let { message } = data;
        // Swal.fire(getAlertToast("Error", message));
        CustomMessage(message, "error", enqueueSnackbar); //call toster
      } finally {
        setLoading(false);
      }
    } else {
      // Swal.fire(getAlertToast("error", "No file is found!"));
      CustomMessage("No file is found!", "error", enqueueSnackbar); //call toster
    }
  };

  useEffect(() => {
    GetFileAccess();
  }, []);

  const [page, setPage] = React.useState(1);
  const handleChangepage = (event, value) => {
    console.log(value);
    setPage(value);
    setPageNumber(value);
  };
  const transformOptionsPdf = {
    defaultScale:1,
   defaultPositionX:200,
  defaultPositionY:100

  }
  const transformOptionsPng = {
    defaultScale:1,
    defaultPositionX:0,
  defaultPositionY:0

  }

  const BootstrapDialogTitle = () => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2,}} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={Toggle}
      maxWidth={maxWidth}
      onClose={DetailsToggle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={DetailsToggle}
      >
        Modal title
      </BootstrapDialogTitle>
      <DialogContent sx={{ position: "relative",}}>
        
           <TransformWrapper options={extensionOfUrl =="pdf" ? transformOptionsPdf : transformOptionsPng} > 
        
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <Fragment>
              {flag && (
                <>
                  <Typography variant="h4">Invoice</Typography>
                  <Box className={styleclasses.tools}>
                    <IconButton
                      aria-label="add"
                      className={styleclasses.previewbtn}
                    >
                      <AddCircleOutlineRoundedIcon onClick={zoomIn} />
                    </IconButton>
                    <IconButton
                      aria-label="out"
                      className={styleclasses.previewbtn}
                    >
                      <RemoveCircleOutlineRoundedIcon onClick={zoomOut} />
                    </IconButton>
                  </Box>
                  <Box className={styleclasses.popupclose}>
                    <CloseIcon className={styleclasses.popupcloseicon}  onClick={DetailsToggle} />
                  </Box>
                </>
              )}

              {/* <canvas id="myCanvas" height="100" weight="100"></canvas> */}
              <TransformComponent>
                {/* {url && ( */}
                {extensionOfUrl == "pdf" ?
                <Document
                  className="popuppdf"
                  file={url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={onDocumentError}
                  overflow="scroll"
                  width="100"
                  noData="Loading PDF..."
                >
                  <Page
                    pageNumber={pageNumber}
                    // width="100"
                    // overflow="scroll"
                  />
                </Document>:
                <img  className="pdfview"  
                 style={{ width: "100%" }} 
                 src={url} 
                 onLoad={onImageSuccess}
                 noData="Loading File..."></img>}
                {/* )} */}
                {/* <canvas
                      data-document_id={0}
                      id={`canvas_${0}`}
                      style={{
                        border: "2px solid #1b3e6f",
                        display: "inline-block",
                        cursor: "pointer",
                      }}
                    ></canvas> */}
              </TransformComponent>
            </Fragment>
          )}
        </TransformWrapper>
        {numPages ? (
          <Stack spacing={2}>
            <Pagination
              count={numPages}
              page={page}
              onChange={handleChangepage}
            />
          </Stack>
        ) : (
          ""
        )}
      </DialogContent>
    </Dialog>
  );
}
