import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  Divider,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { SUPPLIER_REPORT_URL } from "../comman/url";
import API from "../constants/API";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { debounce, initial } from "underscore";
import moment from "moment";
import { CSVLink } from "react-csv";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MetrixSupplierFilter from "../components/Modal/MetrixSupplierFilter";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import PagenationPages from "../components/PagenationPages";
import { Badge } from "reactstrap";
import { statusStyleConfig } from "../constants/HelperConstant";
import Loader from "../comman/Loader";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  approvedbadge: {
    background: "#793ED608 !important",
    color: "#793ED6 !important",
    fontSize: "14px !important",
    textTransform: "none !important",
    padding: "4px 16px !important",
    "&:before": {
      background: "#793ED630 !important",
    },
  },
  paidbadge: {
    background: "#793ED608 !important",
    color: "green !important",
    fontSize: "14px !important",
    textTransform: "none !important",
    padding: "4px 16px !important",
    "&:before": {
      background: "#793ED630 !important",
    },
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  bluebox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#3779EC !important",
    borderLeftWidth: "8px  !important",
  },
  greenbox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#0E9F85 !important",
    borderLeftWidth: "8px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    padding: "10px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    marginRight: "20px !important",
    width: "110px !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
    "&:disabled": {
      backgroundColor: "#A0A3BD !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  exportcsvmenu: {
    fontSize: "12px !important",
    letterSpacing: "0px !important",
    color: "#A0A3BD !important",
    padding: "5px 14px !important",
  },
  arrowtip: {
    display: "flex",
    justifyContent: "center",
  },
  sortByDate: {
    display: "none",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  let pattern = /^\d+$/;
  let avalue = a[orderBy];
  let bvalue = b[orderBy];
  if (orderBy == "invoiceAmount") {
    avalue = parseInt(a[orderBy]);
    bvalue = parseInt(b[orderBy]);
  }
  if (orderBy == "invoiceNumber") {
    if (pattern.test(avalue)) {
      avalue = parseInt(a[orderBy]);
      bvalue = parseInt(b[orderBy]);
    } else {
      avalue = a[orderBy];
      bvalue = b[orderBy];
    }
  }
  if (bvalue < avalue) {
    return -1;
  }
  if (bvalue > avalue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => ascending(a, b, orderBy);
}
function ascending(a, b, orderBy) {
  let pattern = /^\d+$/;
  let avalue = a[orderBy];
  let bvalue = b[orderBy];
  if (orderBy == "invoiceAmount") {
    avalue = parseInt(a[orderBy]);
    bvalue = parseInt(b[orderBy]);
  }
  if (orderBy == "invoiceNumber") {
    if (pattern.test(bvalue, avalue)) {
      avalue = parseInt(a[orderBy]);
      bvalue = parseInt(b[orderBy]);
    } else {
      avalue = a[orderBy];
      bvalue = b[orderBy];
    }
  }
  if (bvalue < avalue) {
    return 1;
  }
  if (bvalue > avalue) {
    return -1;
  }
  return 0;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "invoiceNumber",
    numeric: true,
    disablePadding: false,
    label: "Invoice Number",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Supplier Name",
  },
  {
    id: "actionByRole",
    numeric: true,
    disablePadding: false,
    label: "User Role",
  },

  {
    id: "invoiceCurrency",
    numeric: true,
    disablePadding: false,
    label: "Currency",
  },
  {
    id: "invoiceAmount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  // {
  //   id: "invCreatedDate",
  //   numeric: true,
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={`${headCell.id != "status" && "custom-border"} 
          
            ${headCell.id == "name" && styleclasses.arrowtip}`}
            //   className={
            //     headCell.label !== "Status"
            //       ? `custom-border
            // ${headCell.id == "name" && styleclasses.arrowtip}`
            //       : ""
            //   }
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
            {headCell.id == "name" && <ArrowDropDownRoundedIcon />}
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));

export default function Metsuppliertable(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("invCreatedDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [supplierData, setSupplierData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalrecords, setTotalRecords] = useState(0);
  const [reportSupplierModelF, setreportSupplierModelF] = useState(false);
  const { classes } = props;
  const styleclasses = useStyles();
  const [dataparams, setdataParams] = useState({
    count: 10,
    offset: 0,
    fromDate: "",
    toDate: "",
    supplier: "",
    approvedByRole: "",
    invoiceStatus:""
  });
  const [showexport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [recordsCsvData, setRecordsCsvData] = useState([]);
  const [filterModal, setfilterModal] = React.useState(false);
  const [totalInvoiceAmt, settotalInvoiceAmt] = useState(0);
  const filterToggle = () => setfilterModal((v) => !v);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openExport = Boolean(anchorEl);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const openFilterHandle = () => {
    setreportSupplierModelF(true);
  };
  const closeFilterHandler = () => {
    setreportSupplierModelF(false);
  };

  //Api function
  const getSupplierReports = async (paramValues) => {
    const config = {
      method: "GET",
      url: SUPPLIER_REPORT_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            ...paramValues,
            supplier: paramValues.supplier?.replace("'", "\\'"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      setLoading(true);
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data, count, invoiceAmount } = JSON.parse(l);
      if (status === "Success") {
        var regExp = /[a-zA-Z]/g;
        console.log(data);
        setSupplierData(
          data.map((item) => {
            return {
              ...item,
              invoiceNumber: item.invoiceNumber || "N/A",
              invoiceAmount: item.invoiceAmount || 0,
              actionByRole: item.actionByRole || "N/A",
              // invoiceSortNumber:
              //   ["", "N/A"].includes(item.invoiceNumber) ||
              //   regExp.test(item.invoiceNumber)
              //     ? 0
              //     : item.invoiceNumber
            };
          })
        );
        setTotalRecords(count);
        settotalInvoiceAmt(invoiceAmount);

        // setLoading(false);
      }
    } catch (error) {
      console.log(error.response);
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  const delaySearch = useRef(
    debounce((dataparams) => getSupplierReports(dataparams))
  ).current;

  useEffect(() => {
    delaySearch(dataparams);
  }, [dataparams]);
  const filterHandler = (filterData) => {
    setdataParams((value) => ({
      ...value,
      ...filterData,
      offset: 0,
    }));
  };
  const fetchCsvData = async () => {
    const { offset, fromDate, toDate, senderEmail ,invoiceStatus} = dataparams;
    setExportLoading(true);
    const config = {
      method: "GET",
      url: SUPPLIER_REPORT_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            count: totalrecords,
            supplier:dataparams.supplier?.replace("'", "\\'"),
            // ...dataparams,
            offset: offset,
            fromDate: fromDate
              ? moment(fromDate).format("YYYY/MM/DD")
              : "",
            toDate: toDate
              ? moment(toDate).format("YYYY/MM/DD")
              : "",
            senderEmail: senderEmail,
            invoiceStatus:invoiceStatus
          })
        ),
        flutterString: "",
      },
    };

    try {
      setLoading(true);

      const response = await API(config);
      // const { status, data, count, invoiceAmount, standardDeviationAmount } =
      //   response.data;
      let l = deCryptFun(response.data);
      const { status, data, count, invoiceAmount, standardDeviationAmount } =
        JSON.parse(l);
      if (status === "Success") {
        setRecordsCsvData(data);
        setShowExport(true);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
      setExportLoading(false);

      //  ref.current.link.click()
    }
  };
  const rowHandle = (pageCount) => {
    setdataParams((v) => ({ ...v, count: pageCount.target.value, offset: 0 }));
  };
  const nextPage = () => {
    setdataParams((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previousPage = () => {
    setdataParams((va) => ({ ...va, offset: va.offset - 1 }));
  };
  const headers = [
    { label: "Invoice Number", key: "invoiceNumber" },
    { label: "Supplier Name", key: "supplierName" },
    { label: "User Role", key: "actionByRole" },
    { label: "Currency", key: "invoiceCurrency" },
    { label: "Amount", key: "invoiceAmount" },
    { label: "Invoice Status", key: "status" },
  ];
  const getDownloadFileName = () => {
    return "VendorsReport";
  };
  useEffect(() => {
    fetchCsvData();
  }, [totalrecords, setTotalRecords]);

  const exportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Grid item xs={12} md={12} sx={{ display: "flex", mb: 2 }}>
        <Grid item xs={12} md={5} lg={5} sx={{ display: "flex" }}>
          <Grid item xs={12} sx={{ margin: "8px 8px 16px 0px" }}>
            <Item className={styleclasses.bluebox}>
              <Typography
                align="left"
                sx={{
                  pb: 2,
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "#14142A",
                }}
              >
                Total Invoices
              </Typography>
              <Typography
                align="left"
                sx={{ fontWeight: 700, fontSize: "17px", color: "#3779EC" }}
              >
                {totalrecords}
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} sx={{ margin: "8px 8px 16px 0px" }}>
            <Item className={styleclasses.greenbox}>
              {" "}
              <Typography
                align="left"
                sx={{
                  pb: 2,
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "#14142A",
                }}
              >
                Invoice Amount
              </Typography>
              <Typography
                align="left"
                sx={{ fontWeight: 700, fontSize: "17px", color: "#0E9F85" }}
              >
                {totalInvoiceAmt?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{" "}
                USD
              </Typography>
            </Item>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          lg={7}
          sx={{ textAlign: "right", alignSelf: "center" }}
        >
          {/* <Tooltip
            title="Export as CSV"
            placement="top"
            arrow
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
          > */}
          <Button
            className={styleclasses.notifydownloadbtn}
            variant="contained"
            id="basic-button"
            aria-controls={openExport ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openExport ? "true" : undefined}
            onClick={exportClick}
            disabled={exportLoading || showexport === false || loading}
          >
            Export
          </Button>
          {/* </Tooltip> */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openExport}
            onClose={exportClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <CSVLink
              key={recordsCsvData ? `csv_${recordsCsvData.length}` : ""}
              data={recordsCsvData}
              headers={headers}
              filename={`${getDownloadFileName()}.csv`}
              target="_blank"
            >
              <MenuItem data-flag="csv" className={styleclasses.exportcsvmenu}>
                CSV SuppliersReport
              </MenuItem>
            </CSVLink>
          </Menu>
          <Tooltip
            title="Filter by"
            placement="top"
            arrow
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
          >
            <IconButton onClick={openFilterHandle}>
              <FilterListRoundedIcon className={styleclasses.filtercolor} />
            </IconButton>
          </Tooltip>
          {reportSupplierModelF && (
            <MetrixSupplierFilter
              openModal={reportSupplierModelF}
              closeModal={closeFilterHandler}
              filterHandler={filterHandler}
              dataparams={dataparams}
            />
          )}
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%", mb: 2, borderRadius: "12px" }}>
        <EnhancedTableToolbar numSelected={selected.length} />

        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {loading && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}

              {!loading && supplierData && supplierData.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <NoRecordFound />
                  </TableCell>
                </TableRow>
              )}

              <>
                {!loading &&
                  stableSort(supplierData, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        invoiceId,
                        invoiceNumber,
                        supplierName,
                        invoiceAmount,
                        invoiceCurrency,
                        status,
                        createdDate,
                        actionByRole,
                      } = row;
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={invoiceId}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                          >
                            {invoiceNumber}
                          </TableCell>
                          <TableCell align="center">{supplierName}</TableCell>
                          <TableCell align="center">{actionByRole}</TableCell>
                          <TableCell align="center">
                            {invoiceCurrency}
                          </TableCell>
                          <TableCell align="center">
                            {invoiceAmount > 0 ? invoiceAmount : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                          {(status === "Approved" || status === "Paid") ? (
                            <Button
                            // color={statusStyleConfig[status]}
                            sx={{ fontWeight: 500 }}
                            className={status === "Approved" ? styleclasses.approvedbadge : styleclasses.paidbadge }
                          >
                            {status}
                          </Button>
                          ): (
                            <Badge color={statusStyleConfig[status]}>
                              {status}
                            </Badge>
                          )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </>

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>{" "}
      <PagenationPages
        dataparams={dataparams}
        rowHandle={rowHandle}
        totalRecord={totalrecords}
        previorsPage={previousPage}
        nextPage={nextPage}
      />
    </Box>
  );
}