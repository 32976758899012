import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Button, Input, UncontrolledCollapse, Spinner } from "reactstrap";

import CircularProgress from "@mui/material/CircularProgress";
import { Select, MenuItem, DialogActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import NativeSelect from "@mui/material/NativeSelect";
import CustomTableEmptyRecord from "../../comman/CustomTableEmptyRecord";

import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import {
  INVOICE_LINE_DELETE_URL,
  GET_INVOICE_FIELDS,
  INVOICE_LINE_LISTING,
  GET_TABLE_VALUES_BY_ID,
  TRAINING_DATA_STORAGE_URL,
  SAVE_TRAINING_DATASET_URL,
  UPDATE_INVOICE_STATUS,
  SAVE_INVOICE_LINE_ITEMS,
  UPDATE_INV_PROCESS_TIME,
  SAVE_AUDIT,
} from "../../comman/url";
import API from "../../constants/API";
import Create from "./Create";
import { FaEdit, FaTrash, FaRegEye } from "react-icons/fa";
import Swal from "sweetalert2";
import Update from "./Update";
import CloseIcon from "@mui/icons-material/Close";

import moment from "moment";

import {
  deCryptFun,
  enCryptFun,
  getRole,
  getSorting,
  getSubTeamId,
  getTeamID,
  getUserId,
  stableSort,
  getApprovalAmount,
} from "../../comman/function";
import { Fragment } from "react";
import { detect, values } from "underscore";
import { CustomMessage } from "../../comman/helperfunction";
import { useSnackbar } from "notistack";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material";
import { set } from "date-fns";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f0f1f5",
    color: theme.palette.common.black,
    lineHeight: "1rem",
    padding: "16px 12px !important",
    FontWeight: "600",
    width: "auto",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px !important",
    fontWeight: 400,
  },
}));

const useStyles = makeStyles((theme) => ({
  instatusbtn: {
    color: "#ec7c37",
    padding: "10px",
    marginTop: "8px",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#1976d2",
    },
  },
  approvebtn: {
    width: "135px",
    color: "#fff",
    textTransform: "capitalize",
    background: "#2B88F0 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: "1",
    border: "none",
    padding: "10px",
    marginTop: "8px",
    cursor: "pointer",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#1976d2",
    },
    "&:disabled": {
      backgroundColor: "#A0A3BD !important",
      cursor: "auto",
    },
  },
  linedialog: {
    placeSelf: "center",
  },
  linedialoginput: {
    display: "flex",
    alignItems: "center",
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",
    marginRight: "12px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  tableCell: {
    whiteSpace: "nowrap",
  },
});

const initialValue = {
  operatingUnit: "",
  invoiceLineNumber: "",
  invoiceLineType: "",
  GLDate: "",
  glAccount: "",
  extendedPrice: "",
  unitOfMeasure: "",
  invoiceId: "",
  itemDescription: "",
  itemNumber: "",
  poLineNumber: "",
  poNumber: "",
  quantity: "",
  unitPrice: "",
  lineNumber:""
};
export default function LineList(props) {
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const [createInvoiceModal, setcreateInvoiceModal] = useState(false);
  const invoiceToggle = () => setcreateInvoiceModal(!createInvoiceModal);
  const [updateInvoiceModal, setupdateInvoiceModal] = useState(false);
  const updateinvoiceToggle = () => setupdateInvoiceModal(!updateInvoiceModal);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [requiredFieldList, setRequiredFieldList] = useState([]);
  const [flag, setflag] = useState(true);
  const [formData, setformData] = useState(initialValue);
  //const [index, setIndex] = useState("0");
  const [indexvalue, setIndexValue] = useState(0);
  const [dialogueView, setDialogueView] = useState("");
  const [tableDropdown, setTableDropdown] = useState([]);
  const [selectedTableTitle, setSelectedTableTitle] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [open, setOpen] = useState(false);
  const subTeamId = getSubTeamId();
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [edit, setEdit] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [fieldChanged, setFieldChanged] = useState(false);
  const [dropData, setDropData] = useState({});
  const [editTime, setEditTime] = useState();
  const [addEditTime, setaddEditTime] = useState();

  // const [fieldArray, setFieldArray] = useState([
  //   {
  //     fieldName: "",
  //     targetVariable: "quantity",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "unitOfMeasure",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "unitPrice",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "operatingUnit",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "glAccount",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "GLDate",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "extendedPrice",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "itemDescription",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "poLineNumber",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "poNumber",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "invoiceLineType",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "invoiceLineNumber",
  //   },
  //   {
  //     fieldName: "",
  //     targetVariable: "itemNumber",
  //   },
  // ])

  const invoiceTitle = [
    { title: "#" },
    { title: "Operating Unit" },
    { title: "Invoice Line Number" },
    { title: "Invoice Line Type" },
    { title: "Actions" },
  ];
  let today = new Date();
  const [invoiceList, setInvoiceList] = useState(initialValue);
  const {
    invoiceID,
    status,
    detectedTableData,
    getPreSelectValues,
    type,
    GlCode,
    filePath,
    values,
    fieldArray,
    setFieldArray,
    FieldCheck,
    EditChangeLines,
    invoiceStatus,
    invoiceFilePath,
    invCreatedDate,
    extensionOfUrl,
    previewUrl,
    getAudit,
  } = props;

  //console.log("detectedTableData", detectedTableData)

  // let detectedTableData = {invoiceDate: {
  //     label: "ORDER DATE",
  //     detectedLabel: "ORDER DATE"
  //   },
  //   invoiceNumber: {
  //     label: "NUMBER",
  //     detectedLabel: "NUMBER"
  //   },
  //   orderNumber: {
  //     label: "P.O. NUMBER",
  //     detectedLabel: "P.O. NUMBER"
  //   },
  //   taxTotal: {
  //     label: "Sales Tax",
  //     detectedLabel: "Sales Tax"
  //   },
  //   invoiceAmount: {
  //     label: "TOTAL DUE",
  //     detectedLabel: "TOTAL DUE"
  //   },
  //   itemDescription: {
  //     label: "DESCRIPTION",
  //     detectedLabel: "DESCRIPTION"
  //   },
  //   extendedPrice: {
  //     label: "EXTENDED PRICE",
  //     detectedLabel: "EXTENDED PRICE"
  //   },
  //   itemNumber: {
  //     label: "PART NO.",
  //     detectedLabel: "PART NO."
  //   },
  //   quantity: {
  //     label: "QUANTITY SHIPPED",
  //     detectedLabel: "QUANTITY SHIPPED"
  //   },
  //   unitOfMeasure: {
  //     label: "UNIT",
  //     detectedLabel: "UNIT"
  //   },
  //   unitPrice: {
  //     label: "UNIT PRICE",
  //     detectedLabel: "UNIT PRICE"
  //   },
  // }

  useEffect(() => {
    // let data = [
    //   { [detectedTableData.quantity?.label || ""]: "quantity" },
    //   { [detectedTableData.unitOfMeasure?.label || ""]: "unitOfMeasure" },
    //   { [detectedTableData.unitPrice?.label || ""]: "unitPrice" },
    //   { [detectedTableData.operatingUnit?.label || ""]: "operatingUnit" },
    //   { [detectedTableData.glAccount?.label || ""]: "glAccount" },
    //   { [detectedTableData.GLDate?.label || ""]: "GLDate" },
    //   { [detectedTableData.extendedPrice?.label || ""]: "extendedPrice" },
    //   { [detectedTableData.itemDescription?.label || ""]: "itemDescription" },
    //   { [detectedTableData.poLineNumber?.label || ""]: "poLineNumber" },
    //   { [detectedTableData.poNumber?.label || ""]: "poNumber" },
    //   { [detectedTableData.invoiceLineType?.label || ""]: "invoiceLineType" },
    //   {
    //     [detectedTableData.invoiceLineNumber?.label || ""]: "invoiceLineNumber",
    //   },
    //   { [detectedTableData.itemNumber?.label || ""]: "itemNumber" },
    // ];
    // console.log("Object key:", Object.entries(detectedTableData));
    // for (const [key, value] of Object.entries(detectedTableData)) {
    //   data.push({ [value.label]: key });
    // }
    // console.log("Pushed Data:", data);

    // setLabelList([
    //   { [detectedTableData.quantity?.label || ""]: "quantity" },
    //   { [detectedTableData.unitOfMeasure?.label || ""]: "unitOfMeasure" },
    //   { [detectedTableData.unitPrice?.label || ""]: "unitPrice" },
    //   { [detectedTableData.operatingUnit?.label || ""]: "operatingUnit" },
    //   { [detectedTableData.glAccount?.label || ""]: "glAccount" },
    //   { [detectedTableData.GLDate?.label || ""]: "GLDate" },
    //   { [detectedTableData.extendedPrice?.label || ""]: "extendedPrice" },
    //   { [detectedTableData.itemDescription?.label || ""]: "itemDescription" },
    //   { [detectedTableData.poLineNumber?.label || ""]: "poLineNumber" },
    //   { [detectedTableData.poNumber?.label || ""]: "poNumber" },
    //   { [detectedTableData.invoiceLineType?.label || ""]: "invoiceLineType" },
    //   {
    //     [detectedTableData.invoiceLineNumber?.label || ""]: "invoiceLineNumber",
    //   },
    //   { [detectedTableData.itemNumber?.label || ""]: "itemNumber" },
    // ]);

    setSelectedOption({
      ...selectedOption,
      [detectedTableData.quantity?.label || ""]: "quantity",
      [detectedTableData.unitOfMeasure?.label || ""]: "unitOfMeasure",
      [detectedTableData.unitPrice?.label || ""]: "unitPrice",
      [detectedTableData.operatingUnit?.label || ""]: "operatingUnit",
      [detectedTableData.glAccount?.label || ""]: "glAccount",
      [detectedTableData.GLDate?.label || ""]: "GLDate",
      [detectedTableData.extendedPrice?.label || ""]: "extendedPrice",
      [detectedTableData.itemDescription?.label || ""]: "itemDescription",
      [detectedTableData.poLineNumber?.label || ""]: "poLineNumber",
      [detectedTableData.poNumber?.label || ""]: "poNumber",
      [detectedTableData.invoiceLineType?.label || ""]: "invoiceLineType",
      [detectedTableData.invoiceLineNumber?.label || ""]: "invoiceLineNumber",
      [detectedTableData.itemNumber?.label || ""]: "itemNumber",
    });

    let newTemp = fieldArray.map((el, i) => {
      //let initialVal = ""
      let target = el.targetVariable;
      if (target in detectedTableData) {
        return { ...el, ["fieldName"]: detectedTableData[target].label };
      } else {
        return { ...el };
      }
    });
    //console.log("newTemp", newTemp)
    setFieldArray(newTemp);
  }, [detectedTableData]);

  const onClickEditTable = (columnName, fieldName, isRequired) => {
    if (edit == true && fieldName != "glCode") {
      // console.log("columnName", {
      //     "columnName": columnName, "fieldName": fieldName, "isRequired" : isRequired
      //   })
      // console.log("selectDropDown", selectedDropdown, )
      // console.log("detectedTableData[columnNAme]", detectedTableData[columnName])

      //console.log("fieldArray", fieldArray)
      let filteredData = fieldArray.filter((el) => {
        return el.targetVariable == columnName;
      });
      //console.log("filteredData", filteredData)
      let selectedVal = filteredData[0].fieldName;
      //console.log("fieldName", selectedVal);
      setSelectedDropdown(selectedVal);
      //}

      // let assignField = fieldArray.find(
      //     (element) => element.targetVariable == columnName
      //   ).fieldName = detectedTableData[columnName].label;
      // console.log("assignField", assignField)
      setSelectedTableTitle({
        columnName: columnName,
        fieldName: fieldName,
        isRequired: isRequired,
      });
      setOpen(true);
    } else return null;
  };

  const handleClose = () => {
    setOpen(false);
  };

  //console.log("tableList", tableList, "invoiceList", invoiceList)

  const rowHandlerSelect = (e) => {
    //console.log("e.target.value", e.target.value)

    setFieldChanged(true);
    let val = e.target.value;

    //console.log("selectedTableTitle", selectedTableTitle.columnName)
    setSelectedDropdown(val);
    //console.log("selectedTableTitle",selectedTableTitle, "tableList", tableList, "invoiceList", invoiceList)
    //console.log("detectedDataTable", detectedTableData, "selectedOption", selectedOption)
    // let temp2 = fieldArray;
    // // console.log("temp2 before", temp2)
    // let fafind = temp2.find(
    //   (element) => element.targetVariable == selectedTableTitle.columnName
    // );
    // if (fafind) {
    //   temp2.find(
    //     (element) => element.targetVariable == selectedTableTitle.columnName
    //   ).fieldName = val;
    // }
    // // console.log("temp2 after", temp2)
    // setFieldArray(temp2);

    // let newVal = invoiceList.map((ld, j) => {
    //   //let initialVal = ""
    //   let matchedObj = tableList[j];
    //   console.log("matchedObject", matchedObj);

    //   if (matchedObj != undefined && val in matchedObj) {
    //     // console.log("matchedObj[val]",matchedObj[val])
    //     return {
    //       ...ld,
    //       [selectedTableTitle.columnName]: val != "" ? matchedObj[val] : "",
    //     };
    //   } else return { ...ld };
    //   //console.log('matchedObj', matchedObj, "val", val );
    // });

    // setInvoiceList(newVal);

    //console.log("newStructuredData", newVal)
  };
  const onLabelSubmit = () => {
    let temp2 = fieldArray;
    // console.log("temp2 before", temp2)
    let fafind = temp2.find(
      (element) => element.targetVariable == selectedTableTitle.columnName
    );
    if (fafind) {
      temp2.find(
        (element) => element.targetVariable == selectedTableTitle.columnName
      ).fieldName = selectedDropdown;
    }
    // console.log("temp2 after", temp2)
    setFieldArray(temp2);

    let newVal = invoiceList.map((ld, j) => {
      //let initialVal = ""
      let matchedObj = tableList[j];
      console.log("matchedObject", matchedObj);

      if (matchedObj != undefined && selectedDropdown in matchedObj) {
        // console.log("matchedObj[val]",matchedObj[val])
        return {
          ...ld,
          [selectedTableTitle.columnName]:
            selectedDropdown != "" ? matchedObj[selectedDropdown] : "",
        };
      } else return { ...ld };
      //console.log('matchedObj', matchedObj, "val", val );
    });

    setDropData((e) => ({
      ...e,
      [selectedTableTitle.columnName]: selectedDropdown,
    }));
    console.log(selectedDropdown);
    setInvoiceList(newVal);
    handleClose();
  };

  const onClickEditHandle = () => {
    // EditChangeLines(true)
    if (props.invoiceLocked > 0) {
      props.invoiceLockedMessage();
    } else {
      setEditTime(new Date());
      setEdit(true);
    }
  };

  const getIvoiceListing = async () => {
    const config = {
      method: "GET",
      url: INVOICE_LINE_LISTING,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        // invoiceLineId: selectedInvoice,
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            invoiceLineId: selectedInvoice,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      // const newArr = [];
      console.log("invoice get line", data, GlCode);

      // data.map((obj, i) => {
      //   return newArr.push((obj.glAccount = GlCode));
      // });
      // console.log("new", newArr);
      setInvoiceList(data);
    } catch (error) {
      // console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const getEditInvoice = (editinvoiceId) => {
    //console.log("invoiceLineId", editinvoiceId)
    // console.log("invoiceLiat", invoiceList)
    let filterEditData = invoiceList.filter((el) => {
      return editinvoiceId == el.invoiceLineId;
    });
    // console.log("filtered invoices", filterEditData)
    setformData(filterEditData[0]);
    // console.log("filterData", filterEditData)
  };

  // const getSingleIvoiceListing = async (invoiceLineId) => {
  //   const config = {
  //     method: "GET",
  //     url: INVOICE_LINE_LISTING,
  //     headers: authHeader(),
  //     params: {
  //       // invoiceId: invoiceID,
  //       // invoiceLineId: invoiceLineId,
  //       webString: enCryptFun(
  //         JSON.stringify({
  //          invoiceId: invoiceID,
  //       invoiceLineId: invoiceLineId,
  //         })
  //       ),
  //       flutterString: "",
  //     },
  //   };
  //   try {
  //     const response = await API(config);
  //    // const { status, data } = response.data;
  //     let l = deCryptFun(response.data);
  //     const { status, data } = JSON.parse(l);
  //     setformData(data[0]);
  //   } catch (error) {
  //     //console.error(error);
  //      if (error.response) {
  //       let { data } = error.response

  //      let p = deCryptFun(data);
  //       let  v = JSON.parse(p)
  //      // console.log("error data new",  v.message)
  //      Swal.fire(getAlert("Error", v.message));
  //     }
  //   }
  // };

  const submitCallBack = () => {
    invoiceToggle();
    getIvoiceListing(invoiceID);
  };

  useEffect(() => {
    getIvoiceListing(invoiceID);
    getTableValuesById();
  }, []);

  
  const addInvoiceLine = () => {
    if (props.invoiceLocked > 0) {
      props.invoiceLockedMessage();
    } else {
      setformData({
        operatingUnit: "",
        invoiceLineNumber: "",
        invoiceLineType: "",
        GLDate: moment(invCreatedDate).format("YYYY-MM-DD"),
        glAccount: "",
        extendedPrice: "",
        unitOfMeasure: "",
        invoiceId: "",
        itemDescription: "",
        itemNumber: "",
        poLineNumber: "",
        poNumber: "",
        quantity: "",
        unitPrice: "",
        lineNumber:""
      });
      invoiceToggle();
      setflag(true);
      setaddEditTime(new Date());
    }
  };

  const editInvoiceLine = async (event) => {
    //console.log("dialog", event.currentTarget.dataset.title)
    if (fieldChanged) {
      // Swal.fire(getAlertToast("info", "Please update the changed labels"));
      CustomMessage(
        "Please update the changed labels",
        "info",
        enqueueSnackbar
      ); //call toster
    } else {
      setflag(false);
      const invoiceLineId = event.currentTarget.dataset.invoiceline_id;
      setDialogueView(event.currentTarget.dataset.title);
      // getSingleIvoiceListing(invoiceLineId);
      getEditInvoice(invoiceLineId);
      invoiceToggle();     

      setIndexValue(parseInt(event.currentTarget.dataset.index_number))
     
    }
  };

 
  

  const editInvoiceLine2 = async () => {
      //indexvalue( index + 1)    


    console.log(indexvalue + 1);  
    console.log(invoiceList.length);     
      setIndexValue(indexvalue + 1);
      if(invoiceList && invoiceList.length > 0){    
        if(indexvalue + 1 < invoiceList.length){
          setflag(false);     
          setDialogueView("editLineItem");
       
           console.log("index", invoiceList[indexvalue].invoiceLineId)    
          // getSingleIvoiceListing(invoiceLineId);
          getEditInvoice(invoiceList[indexvalue+1].invoiceLineId);
          setcreateInvoiceModal(true) 
          CustomMessage(
            "Loading next line item",
            "success",
            enqueueSnackbar
          );        
          }else{
            invoiceToggle();
          } 
      }
  
  };


  const viewInvoiceLine = async (event) => {
    //console.log("dialog", event.currentTarget.dataset.title)
    //console.log("iddddddd",event.currentTarget.dataset.invoiceline_id)
    setflag(false);
    const invoiceLineId = event.currentTarget.dataset.invoiceline_id;
    setDialogueView(event.currentTarget.dataset.title);
    // getSingleIvoiceListing(invoiceLineId);
    getEditInvoice(invoiceLineId);
    invoiceToggle();
  };

  const onClickDeleteInvoiceLine = (e) => {
    e.stopPropagation();
    let invoiceLineId = parseInt(e.currentTarget.dataset.invoiceline_id);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this asset?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        deleteInvoiceLine(invoiceLineId);
      }
    });
  };

  const deleteInvoiceLine = async (invoiceLineID) => {
    const config = {
      method: "DELETE",
      url: INVOICE_LINE_DELETE_URL,
      headers: authHeader(),
      params: {
        // invoiceLineId: invoiceLineID ,
        webString: enCryptFun(
          JSON.stringify({
            invoiceLineId: invoiceLineID,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status == "Success") {
        // Swal.fire(
        //   getAlertToast("Success", "Invoice Line deleted Successfully")
        // );
        // saveAudit("Line Item Deleted");
        saveAudit("Edit")
        CustomMessage(
          "Invoice Line deleted Successfully",
          "success",
          enqueueSnackbar
        ); //call toster

        getIvoiceListing();
      } else {
        // Swal.fire(getAlert("error", "error"));
        CustomMessage("error", "error", enqueueSnackbar); //call toster
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetRequiredValues = async () => {
    const config = {
      method: "GET",
      url: GET_INVOICE_FIELDS,
      params: {
        // teamId: getRole() == "Supplier" ? subTeamId : getTeamID() ,
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() == "Supplier" ? subTeamId : getTeamID(),
          })
        ),
        flutterString: "",
      },

      headers: authHeader(),
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      // console.log(response.data);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        // let requiredFields = data.filter((d) => { return d.isMandatory === 1});
        setRequiredFieldList(data);
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const getTableValuesById = async () => {
    const config = {
      method: "GET",
      url: GET_TABLE_VALUES_BY_ID,
      params: {
        // teamId: getRole() == "Supplier" ? subTeamId : getTeamID() ,
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
          })
        ),
        flutterString: "",
      },

      headers: authHeader(),
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, lineItems } = JSON.parse(l);
      // console.log("getTableValues", JSON.parse(l))
      if (status === "Success") {
        // let requiredFields = data.filter((d) => { return d.isMandatory === 1});
        // console.log("getTableValues", lineItems)
        let arrayofItems = Object.keys(lineItems[0]);
        setTableDropdown(arrayofItems);
        setTableList(lineItems);
        // console.log("arrayofItems",arrayofItems)
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        console.log("error data new", v.message);
        //Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const invTime = async () => {
    var cancelDate = Math.abs(
      (new Date().getTime() - editTime.getTime()) / 60000
    );
    const getconfig = {
      method: "PUT",
      url: UPDATE_INV_PROCESS_TIME,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            invProcessingTime: cancelDate.toFixed(4),
            teamId: getTeamID(),
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(getconfig);
      let l = deCryptFun(response.data);

      const { message } = JSON.parse(l);

      if (message === "Success") {
        setEditTime("")
      }
    } catch (err) {}
  };

  const updateInvoiceLineItems = async () => {
  
      saveAudit("Edit");
    
    // setEdit(false)
    //returnin without null values
    let filteredInvoiceList =
      invoiceList &&
      invoiceList.map((el) => {
        let obj = el;
        let result = Object.entries(obj).reduce(
          (a, [k, v]) => (v == null || v == "" ? a : ((a[k] = v), a)),
          {}
        );

        return result;
      });

    const keycongigure = {
      method: "POST",
      url: TRAINING_DATA_STORAGE_URL,
      headers: authHeader(),
      data: {
        invoiceId: parseInt(invoiceID),
        entityDatasetDetails: fieldArray,
      },
    };
    setUpdateLoading(true);
    try {
      const response = await API(keycongigure);
      // console.log("updateLineItems", response)
    } catch (error) {
      console.log("error", error);
    } finally {
      setEdit(false);
      setFieldChanged(false);
    }

    let filteredArray = fieldArray.filter((element) => element.fieldName != "");
    const keycongigure2 = {
      method: "POST",
      url: SAVE_TRAINING_DATASET_URL,
      headers: authHeader(),
      data: {
        supplierName: values.name,
        entityDataset: filteredArray,
        invoiceId: invoiceID,
      },
    };
    try {
      const response = await API(keycongigure2);
    } catch (error) {
      console.log("error", error);
    } finally {
    }
    invTime();

    const config = {
      method: "POST",
      url: SAVE_INVOICE_LINE_ITEMS,
      headers: authHeader(),

      data: {
        webString: enCryptFun(
          JSON.stringify({
            filepath: filePath,
            invoiceLineItems: filteredInvoiceList,
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      // const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      console.log("JSON PARSED IN LIST", JSON.parse(l));
      if (status === "Success") {
        // Swal.fire(getAlertToast("Success", "Updated Successfully"));
        CustomMessage("Updated Successfully", "success", enqueueSnackbar); //call toster
        setDropData({});
        getIvoiceListing(invoiceID);
        getTableValuesById();
        getPreSelectValues();
        setEdit(false);
        setEditTime()
        setFieldChanged(false);
        // // window.location.reload();
        // GetDetails();
        // getPreSelectValues();
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));

        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
        //console.log("v.message")
      }
    } finally {
      setUpdateLoading(false);
      // setButtonClicked("updateButton")
      // setTimerFlag(false);
      // unlockInvoice();
      // setUpdateKey(false)
    }
  };

  const cancelInvoiceLineItems = () => {
    // setDropData({});
    invTime();
    setEdit(false);
    saveAudit("Edit")
    getIvoiceListing();
    getTableValuesById();
    getPreSelectValues();
    setFieldChanged(false);
  };

  const approveInvoice = async () => {
    setApproveLoading(true);
    console.log("supplierName", values.supplierName, "name", values.name);
    // if (keyChangeFlag == true) {

    let filteredArray = fieldArray.filter((element) => element.fieldName != "");
    const keycongigure = {
      method: "POST",
      url: SAVE_TRAINING_DATASET_URL,
      headers: authHeader(),
      data: {
        supplierName: values.name,
        entityDataset: filteredArray,
        invoiceId: invoiceID,
      },
    };
    try {
      const response = await API(keycongigure);
    } catch (error) {
      console.log("error", error);
    } finally {
    }
    // }
    const config = {
      method: "PUT",
      url: UPDATE_INVOICE_STATUS,
      headers: authHeader(),
      data: {
        // userId: getUserId(),
        // invoiceId: invoiceID,
        // status: "Approved",
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
            invoiceId: invoiceID,
            status: "Approved",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        // Swal.fire(getAlertToast("Success", "Approved Successfully"));
        CustomMessage("Approved Successfully", "success", enqueueSnackbar); //call toster

        // setFormValues({ ...formValues, status: "Approved" });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setApproveLoading(false);
      setEdit(false);
    }
  };

  useEffect(() => {
    GetRequiredValues();
  }, [subTeamId]);
  const editIsset = (flag) => {
    EditChangeLines(flag);
  };
  useEffect(() => {
    editIsset(edit);
  }, [edit]);

  const invoiceSave = async (list) => {
    const config = {
      method: "POST",
      url: SAVE_INVOICE_LINE_ITEMS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            filepath: filePath,
            invoiceLineItems: list,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      console.log("JSON PARSED IN LIST", JSON.parse(l));
      if (status === "Success") {
        // Swal.fire(getAlertToast("Success", "Updated Successfully"));
        CustomMessage("Updated Successfully", "success", enqueueSnackbar); //call toster
        // GetRequiredValues();
        getIvoiceListing();
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
        //console.log("v.message")
      }
    } finally {
      setUpdateLoading(false);
    }
  };
  let saveInvoice = useRef();
  useEffect(() => {
    if (!saveInvoice.current) {
      if (
        invoiceList &&
        invoiceList.length > 0 &&
        invoiceList.length < 50 &&
        type == "nonpo" &&
        invoiceList[0].glAccount === null
      ) {
        saveInvoice.current = true;
        const listData =
          invoiceList &&
          invoiceList.map((item) => {
            return {
              operatingUnit: item.operatingUnit || "",
              invoiceLineNumber: item.invoiceLineNumber || "",
              invoiceLineType: item.invoiceLineType || "",
              GLDate: item.GLDate || "",
              extendedPrice: item.extendedPrice || "",
              unitOfMeasure: item.unitOfMeasure || "",
              invoiceId: item.invoiceId || "",
              invoiceLineId: item.invoiceLineId || "",
              itemDescription: item.itemDescription || "",
              itemNumber: item.itemNumber || "",
              poLineNumber: item.poLineNumber || "",
              poNumber: item.poNumber || "",
              quantity: item.quantity || "",
              unitPrice: item.unitPrice || "",
              glAccount: item.glAccount === null ? GlCode : item.glAccount,
            };
          });
        invoiceSave(listData);
      }
    }
  }, [invoiceList]);
  // useEffect(()=>{
  //   ApproveCheck()
  // },[invoiceList])
  const saveAudit = async (comment) => {
    const config = {
      method: "PUT",
      url: SAVE_AUDIT,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            comments: comment,
            actionBy: getUserId(),
            isExceptionResolved: false,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if (message === "Success") {
        getAudit();
      }
    } catch (error) {}
  };
  const ApproveCheck = () => {
    if (!["Supplier"].includes(getRole())) {
      if (
        getRole() === "Team Member" &&
        (values.dueAmount === "" || values.dueAmount > getApprovalAmount())
      ) {
        {
          return <ApproveButton disabled={true} />;
        }
      }
      if (
        invoiceStatus !== "Valid" ||
        values.supplierStatus !== "Valid" ||
        (type == "po" && values.invoicePOStatus !== "Valid") ||
        // (type==="po" && values.orderNumber === "" )||

        [
          "Invoice Rejection",
          "Supplier Maintenance",
          "Specialist Exception",
          "Request Information",
          "Reject Invoice",
          "Rejected",
        ].includes(status)
        // (orderNumber && invoicePOStatus !== "Valid") ||
        // invoicePOStatus !== "Valid" ||
      ) {
        return <ApproveButton disabled={true} />;
      } else {
        return <ApproveButton disabled={false} />;
      }
    } else {
      return <></>;
    }
  };
  const ApproveButton = ({ disabled }) => {
    return (
      <Button
        className={styleclasses.approvebtn}
        onClick={FieldCheck}
        disabled={disabled}
      >
        Approve
      </Button>
    );
  };

  return (
    <div className="invoice-line-details">
      <div
        className="d-flow"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
          marginTop: "18px",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <h5
          style={{
            marginTop: 0,
            marginBottom: "0.6em",
            color: "rgba(0, 0, 0, 0.85)",
            fontWeight: 600,
            fontSize: "16px",
          }}
        >
          Invoice Line Item
        </h5>
        {status == "Approved" || status == "Rejected" || status == "Paid" ? (
          ""
        ) : (
          <Button
            style={{
              color: "rgb(236 124 55)",
              background: "transparent",
              borderRadius: "5px",
              opacity: "1",
              border: "none",
              padding: "6px 15px",
              border: "1px solid",
              height: "32px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
            id="line"
            className="btn-left"
            size="sm"
            onClick={addInvoiceLine}
            // disabled={!edit}
          >
            + Add
          </Button>
        )}
      </div>

      <Grid xs={12} md={12}>
        <TableContainer sx={{ maxHeight: 680 }} component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "auto" }}
            size="small"
            aria-disabled={true}
            responsive
          >
            <TableHead>
              <TableRow>
                {/* <CustomTableHeader tableColumns={invoiceTitle} /> */}
                <StyledTableCell>#</StyledTableCell>
                {edit == true && (
                  <StyledTableCell id="pf" headers="p">
                    Actions
                  </StyledTableCell>
                )}
                {requiredFieldList &&
                  stableSort(
                    requiredFieldList.filter(
                      (i) => i.moduleName == "Invoice Line"
                    ),
                    getSorting("asc", "fieldOrder")
                  )
                    .filter((element) => element.isVisible === 1)
                    .map((record, index) => {
                      const { columnName, fieldName, isRequired } = record;
                      let isDisplay = false;
                      switch (columnName) {
                        case "glAccount":
                          if (type == "nonpo") isDisplay = true;
                          break;

                        case "poNumber":
                          if (type == "po") isDisplay = true;
                          break;

                        case "poLineNumber":
                          if (type == "po") isDisplay = true;

                          break;

                        default:
                          isDisplay = true;
                          break;
                      }

                      return isDisplay ? (
                        <StyledTableCell
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            onClickEditTable(columnName, fieldName, isRequired);
                          }}
                        >
                          {fieldName}
                        </StyledTableCell>
                      ) : (
                        ""
                      );
                    })}
                {/* {type == "nonpo" && requiredFieldList &&
              requiredFieldList.filter(
                (element) =>
                  element.columnName === "glAccount" && element.moduleName == "Invoice Line"
              ).length == 1 ?
              (<th>{requiredFieldList.find(
                (element) =>
                  element.columnName === "glAccount"
              ).fieldName}</th>) : ""} */}

                {/* <th>Operating Unit</th>
            <th>Invoice Line Amount</th>
            <th>Invoice Line Number</th>
            <th>Invoice Line Type</th> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <tr> {requiredFieldList &&
                    stableSort(
                      requiredFieldList.filter(
                        (i) =>
                          i.moduleName == "Invoice Line" && i.isVisible == 1
                      ),
                      getSorting("asc", "fieldOrder")
                    ).map((record, index) => { 
                       const { fieldName } = record;
                      return (
                    <td key={fieldName}>
                      
                       <Input type="select">
                  <option>1</option>
                  <option>2</option>
                </Input> </td>
                )})  } </tr> */}

              {invoiceList && invoiceList.length > 0 ? (
                invoiceList &&
                invoiceList.map((record, index) => {
                  const {
                    invoiceLineNumber,
                    invoiceLineType,
                    operatingUnit,
                    invoiceLineId,
                    GLDate,
                    glAccount,
                    extendedPrice,
                    unitOfMeasure,
                    itemDescription,
                    itemNumber,
                    poLineNumber,
                    poNumber,
                    quantity,
                    unitPrice,
                    glCode,
                  } = record;
                  return (
                    //  <td>
                    //     <Input
                    //               type="select"
                    //               //required={record.isRequired===1}
                    //               name={record.fieldName}
                    //               id={record.fieldName}
                    //               data-labname={record.fieldName}
                    //               data-exaval={record.columnName}
                    //               onChange={(e) => RowHandlerChk(e)}
                    //               value={
                    //                detectedTableData[record.columnName].label ? detectedTableData[record.columnName].label : ""
                    //               }
                    //             >
                    //               <option value={""}></option>

                    //               {tableDropdown.map((i) => {
                    //                 return (
                    //                   <option
                    //                     //style={{ display: Object.keys(selectedOption).length && Object.values(selectedOption).includes(i.label) ? "none" : "inherit" }}
                    //                     key={`col_${i}`}
                    //                     value={i}
                    //                     data-va={i}
                    //                    // disabled={Object.keys(selectedOption).length && Object.values(selectedOption).includes(i.label)}
                    //                   >
                    //                     {i}
                    //                   </option>
                    //                 );
                    //               })}
                    //             </Input>
                    //  </td>

                    <>
                      <TableRow key={`invoice_list_${index}`}>
                        <TableCell>{index + 1}</TableCell>
                        {edit == true && (
                          <TableCell
                            headers="p pf"
                            style={{ display: "table-cell", padding: "0px" }}
                          >
                            {status == "Approved" || status == "Rejected" || status == "Paid" ? (
                              ""
                            ) : (
                              <Fragment>
                                <Button
                                  color=""
                                  size="sm"
                                  className="actionBtn"
                                  onClick={editInvoiceLine}
                                  data-invoiceline_id={invoiceLineId}
                                  data-index_number={index}
                                  //           data-invoice_ounit={operatingUnit}
                                  //           data-invoice_line={invoiceLineNumber}
                                  //           data-invoice_type={invoiceLineType}
                                  //           data-invoice_GLDate = {GLDate}
                                  //           data-invoice_glAccount = {glAccount}
                                  //          data-invoice_extendedPrice =   {extendedPrice}
                                  // data-invoice_unitOfMeasure =  {unitOfMeasure}
                                  // data-invoice_itemDescription =  {itemDescription}
                                  // data-invoice_itemNumber =  {itemNumber}
                                  // data-invoice_poLineNumber =  {poLineNumber}
                                  // data-invoice_poNumber =  {poNumber}
                                  // data-invoice_quantity =  {quantity}
                                  // data-invoice_unitPrice =  {unitPrice}
                                  data-title="editLineItem"
                                  // disabled={status == "Approved" ? true : false}
                                >
                                  <FaEdit></FaEdit>
                                </Button>
                                <Button
                                  color=""
                                  size="sm"
                                  className="actionBtn"
                                  onClick={onClickDeleteInvoiceLine}
                                  data-invoiceline_id={invoiceLineId}
                                >
                                  <FaTrash></FaTrash>
                                </Button>
                                <Button
                                  color=""
                                  size="sm"
                                  className="actionBtn"
                                  onClick={viewInvoiceLine}
                                  data-invoiceline_id={invoiceLineId}
                                  //           data-invoice_ounit={operatingUnit}
                                  //           data-invoice_line={invoiceLineNumber}
                                  //           data-invoice_type={invoiceLineType}
                                  //            data-invoice_GLDate = {GLDate}
                                  //             data-invoice_glAccount = {glAccount}
                                  //          data-invoice_extendedPrice =   {extendedPrice}
                                  // data-invoice_unitOfMeasure =  {unitOfMeasure}
                                  // data-invoice_itemDescription =  {itemDescription}
                                  // data-invoice_itemNumber =  {itemNumber}
                                  // data-invoice_poLineNumber =  {poLineNumber}
                                  // data-invoice_poNumber =  {poNumber}
                                  // data-invoice_quantity =  {quantity}
                                  // data-invoice_unitPrice =  {unitPrice}
                                  data-title="viewLineItem"
                                  // disabled={status == "Approved" ? true : false}
                                >
                                  <FaRegEye></FaRegEye>
                                </Button>
                              </Fragment>
                            )}
                          </TableCell>
                        )}
                        {requiredFieldList &&
                          stableSort(
                            requiredFieldList.filter(
                              (i) =>
                                i.moduleName == "Invoice Line" &&
                                i.isVisible == 1
                            ),
                            getSorting("asc", "fieldOrder")
                          ).map((record, index1) => {
                            //console.log("record", record)
                            const { fieldName } = record;
                            return fieldName == "Invoice Line Type" ? (
                              <TableCell>{invoiceLineType}</TableCell>
                            ) : fieldName == "Operating Unit" ? (
                              <TableCell>{operatingUnit}</TableCell>
                            ) : fieldName == "Invoice Line Number" ? (
                              <TableCell>{invoiceLineNumber}</TableCell>
                            ) : fieldName == "GL Account" && type == "nonpo" ? (
                              <TableCell>
                                {/* {glAccount === "" ? GlCode : glAccount} */}
                                {glAccount}
                              </TableCell>
                            ) : fieldName == "GL Date" ? (
                              <TableCell>
                               
                                {GLDate == "0000-00-00" ||
                                GLDate == null ||
                                GLDate === ""
                                  ?  moment(new Date()).format("YYYY-MM-DD")
                                  : moment(GLDate).format("YYYY-MM-DD")}
                              </TableCell>
                            ) : fieldName == "Extended Price" ? (
                              <TableCell>{extendedPrice}</TableCell>
                            ) : fieldName == "Unit Price" ? (
                              <TableCell>{unitPrice}</TableCell>
                            ) : fieldName == "Quantity" ? (
                              <TableCell>{quantity}</TableCell>
                            ) : fieldName == "Unit of Measure" ? (
                              <TableCell>{unitOfMeasure}</TableCell>
                            ) : fieldName == "Item Description" ? (
                              <TableCell>{itemDescription}</TableCell>
                            ) : fieldName == "Item Number" ? (
                              <TableCell>{itemNumber}</TableCell>
                            ) : fieldName == "PO Line Number" &&
                              type == "po" ? (
                               <TableCell>{poLineNumber ? poLineNumber : index+1}</TableCell>
                             
                            ) : fieldName == "PO Number" && type == "po" ? (
                              <TableCell>{poNumber}</TableCell>
                            
                            ) : (
                              ""
                            );
                          })}
                        {/* {type == "nonpo" && <td>{glAccount == null || glAccount == "" ? GlCode : glAccount}</td>} */}
                        {/* <td>{invoiceLineAmount}</td>
                  <td>{invoiceLineNumber}</td>
                  <td>{invoiceLineType}</td> */}
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <CustomTableEmptyRecord columnsCount={invoiceTitle.length} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {status &&
        // status != "Approved" ||
        (status != "Rejected" || status != "Auto Approved") &&
        status != "Approved" && status != "Paid" && 
        (edit === false ? (
          <TableRow
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "15px",
              justifyContent: "center",
            }}
            className="mt-3"
          >
            {status != "Approved" && status != "Rejected" && status != "Paid" ? (
              <>
                {" "}
                <div className="mr-1">
                  <Button
                    style={{
                      color: " rgb(236, 124, 55)",
                      background: "transparent",
                      border: "none",
                      fontWeight: "500",
                      fontSize: "0.875rem",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    className={styleclasses.instatusbtn}
                    onClick={() => {
                      onClickEditHandle();
                    }}
                  >
                    Edit Lines
                  </Button>
                </div>
                <div>
                  <ApproveCheck />
                </div>
              </>
            ) : null}
          </TableRow>
        ) : (
          <TableRow
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "15px",
              justifyContent: "center",
            }}
            className="mt-2"
          >
            <Button
              style={{
                color: "rgb(236 124 55)",
                textTransform: "capitalize",
                background: "transparent",
                opacity: "1",
                border: "none",
                marginRight: "15px",
                cursor: "pointer",
              }}
              className={styleclasses.instatusbtn}
              disabled={updateLoading}
              onClick={() => {
                updateInvoiceLineItems();
              }}
            >
              Update
            </Button>
            <Button
              style={{
                color: "rgb(236 124 55)",
                textTransform: "capitalize",
                background: "transparent",
                opacity: "1",
                border: "none",
                cursor: "pointer",
              }}
              className={styleclasses.instatusbtn}
              disabled={updateLoading}
              onClick={() => {
                cancelInvoiceLineItems();
              }}
            >
              Cancel
            </Button>
          </TableRow>
        ))}

      <Create
        flag={flag}
        formData={formData}
        createInvoiceModal={createInvoiceModal}
        invoiceToggle={invoiceToggle}
        submitCallBack={submitCallBack}
        updateInvoiceLineItems={updateInvoiceLineItems}
        invoiceID={invoiceID}
        GlCode={GlCode}
        type={type}
        selectedInvoice={selectedInvoice}
        dialogueView={dialogueView}
        invoiceList={invoiceList}
        invoiceFilePath={invoiceFilePath}
        extensionOfUrl={extensionOfUrl}
        previewUrl={previewUrl}
        timeproccess={addEditTime}
        getAudit={getAudit}
        invoiceListlen={invoiceList.length}
        editInvoiceLine={editInvoiceLine2}
        indexvalue={indexvalue}
      />
      {/* <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle className={styleclasses.linedialog}>
          Select Label
        </DialogTitle> */}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: "600px",
            maxWidth: "75%",
            "&::-webkit-scrollbar": {
              width: "0.5em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A0A3BD",
              outline: "1px solid #A0A3BD",
              borderRadius: "50px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Select Label
        </BootstrapDialogTitle>

        <DialogContent>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="Select "
            className={styleclasses.linedialoginput}
            onChange={(e) => rowHandlerSelect(e)}
            value={selectedDropdown}
          >
            <MenuItem
              key={""}
              value={""}
              data-va={""}
              style={{ height: "40px" }}
            />
            {tableDropdown
              .filter((e) => e == selectedDropdown)
              .map((i) => {
                return (
                  <MenuItem key={`col_${i}`} value={i} data-va={i}>
                    {i}
                  </MenuItem>
                );
              })}

            {tableDropdown
              // .filter((e) =>
              //   [
              //     "DESCRIPTION",
              //     "PART IDENTIFIER",
              //     "QUANTITY",
              //     "UNIT PRICE",
              //   ].includes(e)
              // )
              .filter(
                (e) =>
                  !Object.values(dropData).includes(e) &&
                  e != selectedDropdown &&
                  e != ""
              )
              .map((i) => {
                console.log(selectedDropdown);
                console.log(i, i == selectedDropdown);
                return (
                  <MenuItem
                    //style={{ display: Object.keys(selectedOption).length && Object.values(selectedOption).includes(i.label) ? "none" : "inherit" }}
                    key={`col_${i}`}
                    value={i}
                    data-va={i}
                    // disabled={Object.keys(selectedOption).length && Object.values(selectedOption).includes(i.label)}
                  >
                    {i}
                  </MenuItem>
                );
              })}
          </Select>

          <DialogActions
            style={{
              justifyContent: "center",
              padding: "8px 16px 16px 16px",
              margin: "auto",
              display: "table !important",
            }}
          >
            <Button
              onClick={onLabelSubmit}
              className={styleclasses.canceldialog}
            >
              Ok
            </Button>
          </DialogActions>
        </DialogContent>
      </BootstrapDialog>
      {/* </Dialog> */}
    </div>
  );
}