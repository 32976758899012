import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { INVOICEPREVIEW_ROUTE } from "../constants/RoutePaths";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { deCryptFun, enCryptFun } from "../comman/function";
import {
  Link,
  useParams,
  useHistory,
  Prompt,
  useNavigate,
} from "react-router-dom";
import { authHeader, getAlert, getAlertToast } from "../comman/mainfunction";
import {
  ACCESS_S3_FILE,
  GET_INVOICE_DETAILS,
  UPDATE_INVOICE,
  RESUBMIT_INITIALIZED_INVOICE_URL,
} from "../comman/url";
import API from "../constants/API";
import Axios from "axios";
import { NoRecordFound } from "../components/NoRecordFound";
import { Badge } from "reactstrap";
import { statusStyleConfig } from "../constants/HelperConstant";
import Loader from "../comman/Loader";
import moment from "moment";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
  },
}));
const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    padding: "10px 15px",
    borderRadius: "12px",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  correctbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#CCF1EA !important",
    color: "#0E9F85 !important",
    boxShadow: "none",
    minWidth: "50px !important",
  },
  infobutton: {
    margin: "0px 16px !important",
    minWidth: "50px !important",
    backgroundColor: "#4E4B6615 !important",
    color: "#A0A3BD !important",
    boxShadow: "none",
  },
  cancelbutton: {
    margin: "0px 16px !important",
    minWidth: "50px !important",
    backgroundColor: "#F14A4A15 !important",
    color: "#F14A4A !important",
    boxShadow: "none",
  },
  startICon: {
    marginRight: "0px !important",
    "& .MuiSvgIcon-root": {
      fontSize: "25px !important",
      paddingLeft: "2px",
    },
  },
  approvedbadge: {
    background: "#793ED608 !important",
    color: "#793ED6 !important",
    fontSize: "14px !important",
    textTransform: "none !important",
    padding: "4px 16px !important",
    "&:before": {
      background: "#793ED630 !important",
    },
  },
  paidbadge: {
    background: "#793ED608 !important",
    color: "green !important",
    fontSize: "14px !important",
    textTransform: "none !important",
    padding: "4px 16px !important",
    "&:before": {
      background: "#793ED630 !important",
    },
  },
  tableContainerbg: {
    background: "white !important",
    overflowX: "auto",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
    borderRadius: "12px !important",
  },
  sortByDate: {
    display: "none",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),

  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  let pattern = /^\d+$/;
  let avalue = a[orderBy];
  let bvalue = b[orderBy];

  if (orderBy == "invoiceAmount") {
    avalue = parseInt(a[orderBy]);
    bvalue = parseInt(b[orderBy]);
  }
  if (orderBy == "invoiceNumber") {
    if (pattern.test(avalue)) {
      avalue = parseInt(a[orderBy]);
      bvalue = parseInt(b[orderBy]);
    } else {
      avalue = a[orderBy];
      bvalue = b[orderBy];
    }
  }
  if (bvalue < avalue) {
    return -1;
  }
  if (bvalue > avalue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => ascending(a, b, orderBy);
}
function ascending(a, b, orderBy) {
  let pattern = /^\d+$/;
  let avalue = a[orderBy];
  let bvalue = b[orderBy];
  if(orderBy=="createdDate"){
   
    avalue=new Date(a[orderBy]).getTime();
    bvalue=new Date(b[orderBy]).getTime();
  }
  if (orderBy == "invoiceAmount") {
    avalue = parseInt(a[orderBy]);
    bvalue = parseInt(b[orderBy]);
  }
  if (orderBy == "invoiceNumber") {
    if (pattern.test(bvalue, avalue)) {
      avalue = parseInt(a[orderBy]);
      bvalue = parseInt(b[orderBy]);
    } else {
      avalue = a[orderBy];
      bvalue = b[orderBy];
    }
  }
  if (bvalue < avalue) {
    return 1;
  }
  if (bvalue > avalue) {
    return -1;
  }
  return 0;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "createdDate",
    numeric: true,
    disablePadding: false,
    label: "Date and Time",
  },
  {
    id: "invoiceSource",
    numeric: true,
    disablePadding: false,
    label: "Invoice Source",
  },
  {
    id: "invoiceNumber",
    numeric: true,
    disablePadding: false,
    label: "Invoice Number",
  },

  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Supplier",
  },
  // {
  //   id: "senderEmail",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Email",
  // },

  {
    id: "invoiceAmount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "currency",
    numeric: true,
    disablePadding: false,
    label: "Currency",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  // {
  //   id: "validationstatus",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Validation Status",
  // },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  // {
  //   id: "invCreatedDate",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "invCreatedDate",

  // }
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={`${headCell.id != "action" && "custom-border"}`}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              // disabled={true}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
            {/* {headCell.id == "validationstatus" && (
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                (Invoice/Supplier/PO)
              </Typography>
            )} */}
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));

export default function InvoiceTable(props) {
  var fileDownload = require("js-file-download");
  let currentUTCDate=moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss")
  const { invoiceDataList = [], loading, Refresh } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [resubmitted, setResubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  let navigate = useNavigate();
  const [resubmitLoading, setResubmitLoading] = useState(false);
  const [invoiceIdCheck, setInvoiceIdCheck] = useState("");
  const [isActive, setIsActive] = useState("");
  const validationStatusConfig = {
    "Not Verified": (
      <Tooltip
        arrow
        classes={{
          arrow: styleclasses.arrow,
          tooltip: styleclasses.tooltip,
        }}
        title="This invoice has not passed the validation check"
        placement="top"
      >
        <Button
          className={styleclasses.infobutton}
          classes={{ startIcon: styleclasses.startICon }}
          startIcon={<InfoOutlinedIcon />}
        ></Button>
      </Tooltip>
    ),
    Valid: (
      <Tooltip
        title="This invoice has passed invoice validation checks"
        placement="top"
        arrow
        classes={{
          arrow: styleclasses.arrow,
          tooltip: styleclasses.tooltip,
        }}
      >
        <Button
          className={styleclasses.correctbutton}
          classes={{ startIcon: styleclasses.startICon }}
          startIcon={<CheckRoundedIcon />}
        ></Button>
      </Tooltip>
    ),
    "Doesn't exist in ERP": (
      <Tooltip
        title="Doesn't exist in ERP"
        placement="top"
        arrow
        classes={{
          arrow: styleclasses.arrow,
          tooltip: styleclasses.tooltip,
        }}
      >
        <Button
          className={styleclasses.cancelbutton}
          classes={{ startIcon: styleclasses.startICon }}
          startIcon={<ClearSharpIcon />}
        ></Button>
      </Tooltip>
    ),
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const downloadPdf = async (file, invoiceNum, invoiceID) => {
    console.log("fileName", file);
    let extension=file.split('.').pop();
    if (file) {
      const config = {
        method: "POST",
        url: ACCESS_S3_FILE,
        data: { filePath: file },
        headers: authHeader(),
      };
      try {
        const response = await API(config);
        const { status, url } = response.data;

        if (status === "Success") {
          // setDownloadURL(url);

          Axios.get(url, {
            responseType: "blob",
          }).then((res) => {
            if(extension=="png"){
              fileDownload(
                res.data,
                invoiceNum != "N/A"
                  ? `invoice${invoiceNum}.png`
                  : `invoice_${invoiceID}.png`
              );
            }
            else if(extension=="pdf"){
            
            fileDownload(
              res.data,
              invoiceNum != "N/A"
                ? `invoice${invoiceNum}.pdf`
                : `invoice_${invoiceID}.pdf`
            );
            }
            else if(extension=="jpeg"){
            
              fileDownload(
                res.data,
                invoiceNum != "N/A"
                  ? `invoice${invoiceNum}.jpeg`
                  : `invoice_${invoiceID}.jpeg`
              );
              }
              else if(extension=="jpg"){
            
                fileDownload(
                  res.data,
                  invoiceNum != "N/A"
                    ? `invoice${invoiceNum}.jpg`
                    : `invoice_${invoiceID}.jpg`
                );
                }
                else if(extension=="tiff"){
            
                  fileDownload(
                    res.data,
                    invoiceNum != "N/A"
                      ? `invoice${invoiceNum}.tiff`
                      : `invoice_${invoiceID}.tiff`
                  );
                  }
                  else{
                    fileDownload(
                      res.data,
                      invoiceNum != "N/A"
                        ? `invoice${invoiceNum}.pdf`
                        : `invoice_${invoiceID}.pdf`
                    );

                  }

          });
        }
      } catch (error) {
        let errorObj = Object.assign({}, error);
        let { data } = errorObj.response;
        let { message } = data;
        Swal.fire(getAlertToast("Error", message));
      }
    } else {
      Swal.fire(getAlertToast("error", "No file is found!"));
    }
  };
  const handleClick = (event) => {
    const invoiceID = event.currentTarget.dataset.id;
    setIsActive(invoiceID);
    let targetInvoice = invoiceDataList.filter((el) => {
      return invoiceID == el.invoiceId;
    });
    console.log("targetInvoice", targetInvoice);
    const status = event.currentTarget.dataset.status;

    if (status === "Initializing") {
      // Swal.fire(getAlert("error", "Invoice is being processed, please wait."));

      Swal.fire({
        // title: "Invoice is being processed, Do you want to download the file?",
        text: "Invoice is being processed, Do you want to download the file?",
        icon: "Info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("download", targetInvoice[0].filePath);
          downloadPdf(
            targetInvoice[0].filePath,
            targetInvoice[0].invoiceNumber,
            invoiceID
          );
        } else {
          setIsActive("");
        }
      });
    } else if (status === "Reprocessing") {
      Swal.fire(getAlert("error", "Invoice is being processed, please wait."));
    } else {
      // window.open(`${INVOICE_DETAILS_ROUTE}/${invoiceId}`);
      //window.open(`${INVOICEPREVIEW_ROUTE}/${invoiceID}`,'_self');

      navigate(`${INVOICEPREVIEW_ROUTE}/${invoiceID}`, {
        state: { locationChange: true },
      });

      // window.location.href(`${INVOICEPREVIEW_ROUTE}/${invoiceID}`);
    }
  };
  const updateInvoiceDetails = async (restructData, id) => {
    console.log("on update", restructData);
    const config = {
      method: "PUT",
      url: UPDATE_INVOICE,
      headers: authHeader(),

      data: {
        webString: enCryptFun(JSON.stringify(restructData)),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      // const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        if (response.status == 200) {
          getResubmitInvoiceDetails(id);
          CustomMessage(
            "Thanks for resubmitting, your invoice is processing!",
            "success",
            enqueueSnackbar
          ); //call toster
          //   Swal.fire({
          //   title: "Are you sure?",
          //   text: "Thanks for resubmitting, your invoice is processing !",
          //   type: "warning",
          //   showCancelButton: false,
          //   confirmButtonColor: "#3085d6",
          //   cancelButtonColor: "#d33",
          //   confirmButtonText: "Ok",
          // }).then((result) => {
          //   if (result.value) {
          //     getResubmitInvoiceDetails(id)
          //   }
          // });
        }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        Swal.fire(getAlert("Error", v.message));
      }
    }
  };

  const getResubmitInvoiceDetails = async (id) => {
    setResubmitLoading(true);
    const keyconfigure = {
      method: "POST",
      url: RESUBMIT_INITIALIZED_INVOICE_URL,
      headers: authHeader(),
      params: {
        invoiceId: id,
      },
    };
    try {
      const response = await API(keyconfigure);
      console.log("resubmit response", response);
      if (response.status == 200) {
        Refresh();
        setResubmitLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setResubmitLoading(false);
    }
  };

  const getInvoiceByresubmitId = async (id) => {
    setResubmitLoading(true);
    const config = {
      method: "GET",
      url: GET_INVOICE_DETAILS,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: id,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);

      //const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        console.log("invoicedatares", data);
        let now = new Date();
        let restructuredData = data[0];
        restructuredData.resubmitedDate = String(now);
        console.log("restructuredData", restructuredData);
        updateInvoiceDetails(restructuredData, id);
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(getAlertToast("error", message));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        Swal.fire("Error", v.message);
      }
    }
  };

  const onClickResubmitInvoice = (e) => {
    let dataIndex = invoiceDataList.findIndex(
      (item) => item.invoiceId == e.currentTarget.dataset.id
    );
    invoiceDataList[dataIndex].flag = true;

    e.stopPropagation();
    setResubmitted(true);
    const resubmitInvoiceId = e.currentTarget.dataset.id;
    setInvoiceIdCheck(resubmitInvoiceId);
    console.log("Resubmit Id:", resubmitInvoiceId);
    getInvoiceByresubmitId(resubmitInvoiceId);

    console.log("resubmit", resubmitInvoiceId);
    //
    //getResubmitInvoiceDetails(resubmitInvoiceId)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  //Api Function
  console.log(invoiceDataList);
  const localdata = new Date();

  const dateAsString = localdata.toString();
  const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
  return (
    <Grid justify="center" alignItems="center">
      {/* <Grid item xs={12} md={12} style={{ padding: "8px" }}> */}
      <Paper sx={{ mb: 2, borderRadius: "16px" }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
          sx={{ display: "block", textOverflow: "ellipsis" }}
          className={styleclasses.tableContainerbg}
        >
          <Table
            sx={{ minWidth: 700 }}
            className="invoicetablewidth"
            align="left"
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {loading && (
                <TableRow align="center">
                  <TableCell colSpan={7}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {!loading && invoiceDataList && invoiceDataList.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <NoRecordFound />
                  </TableCell>
                </TableRow>
              )}

              <>
                {!loading &&
                  stableSort(invoiceDataList, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((datalist, index) => {
                      const {
                        dueAmount,
                        invoiceAmount,
                        invoiceNumber,
                        invoiceCurrency,
                        status,
                        invoiceId,
                        companyName,
                        name,
                        senderEmail,
                        supplierStatus,
                        invoiceStatus,
                        invoicePOStatus,
                        receiverEmail,
                        supplierCompanyName,
                        isResubmited,
                        actionDate,
                        createdDate,
                        source,
                        flag,
                      } = datalist;

                      const isItemSelected = isSelected(invoiceId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      console.log(isActive, actionDate);
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event)}
                          data-id={invoiceId}
                          data-status={status}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={datalist.invoiceId}
                          selected={isItemSelected}
                          style={{
                            backgroundColor:
                              isActive == invoiceId
                                ? "rgba(0, 0, 0, 0.04)"
                                : "",
                          }}
                        >
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {/* {invCreatedDate} */}
                            {moment(createdDate)
                              .tz(timezone)
                              .format("MM/DD/YYYY , hh:mm A")}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {source}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {invoiceNumber}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {name}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {senderEmail}
                          </TableCell> */}
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {(dueAmount > 0 ? dueAmount : "-") ||
                              (dueAmount == "NaN" ? "-" : dueAmount)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {invoiceCurrency}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {" "}
                            {(status === "Approved" || status === "Paid") ? (
                              <Tooltip
                                placement="top"
                                title={ status === "Approved" ? `Approved on ${moment(actionDate).format(
                                  "MM-DD-YY"
                                )}` : ""}
                                arrow
                                classes={{
                                  arrow: status === "Approved" ? styleclasses.arrow : "",
                                  tooltip: status === "Approved" ? styleclasses.tooltip : "",
                                }}
                              >
                                <Button
                                  // color={statusStyleConfig[status]}
                                  sx={{ fontWeight: 500 }}
                                  className={status === "Approved" ? styleclasses.approvedbadge : styleclasses.paidbadge }
                                >
                                  {status}
                                </Button>
                              </Tooltip>
                            ) : (                              
                              <Badge color={statusStyleConfig[status]}>
                                {status == "Request Information"
                                  ? "Information Requested"
                                  : status}
                              </Badge>
                            )}
                          </TableCell>
                          {/* <TableCell align="center">
                            <Grid
                              item
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              xs={12}
                              md={12}
                              lg={12}
                            >
                              {validationStatusConfig[invoiceStatus]}
                              {validationStatusConfig[supplierStatus]}
                              {validationStatusConfig[invoicePOStatus]}
                            </Grid>
                          </TableCell> */}
                          <TableCell align="center">
                            <Button
                              color="primary"
                              size="sm"
                              className="actionBtn"
                              onClick={(e) => onClickResubmitInvoice(e)}
                              data-id={invoiceId}
                              disabled={
                                (status != "Initializing" &&
                                  status != "Reprocessing") ||
                                isResubmited === 1 ||
                                flag
                                // ||
                                // (invoiceIdCheck == invoiceId && resubmitLoading)
                              }
                              //disabled = {true}
                              style={{
                                padding: "4px 6px 4px 10px",
                              }}
                            >
                              {(status === "Initializing" ||
                                status === "Reprocessing") &&
                              isResubmited === 1 &&
                              (((moment(currentUTCDate)).diff(moment(createdDate),'minutes')) >"05")
                              // resubmitted === true
                                ? "Resubmitted"
                                : "Resubmit"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </>

              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* </Grid> */}
    </Grid>
  );
}
